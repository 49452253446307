.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

td.customCell0 {
  background-color: #ff6f60;
}

td.customCell1 {
  background-color: #ffff6b;
}
.customCell2 {
  background-color: #aee571;
}
.customCell3 {
  background-color: #5ddef4;
}
.customCell4 {
  background-color: #6f74dd;
}
.customCell5 {
  background-color: #ff5c8d;
}
.customCell6 {
  background-color: #4ebaaa;
}
.customCell7 {
  background-color: #76d275;
}
.customCell8 {
  background-color: #f5fd67;
}
